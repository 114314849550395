<template>
  <div>
    <div class="api-subscription-title has-text-grey-dark has-text-centered">Your Credentials</div>
    <Spinner v-show="showInProgressSection" :message="progressMessage"/>
    <DoodleMessage class="has-text-grey-dark" :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
    <DoodleMessage class="has-text-grey-dark" :message="showCopiedMessageText" message-class="has-text-success" v-show="showCopiedMessage"/>
    <div class="has-text-centered" v-if="this.doodleForm.completed">
      <div class="api-subscription-detail">
        <div class="has-text-weight-bold has-text-grey-dark">Client ID
          <a @click="copyToClipboard(clientId)"><font-awesome-icon icon="copy" class="has-text-danger"/></a>
        </div>
        <div class="has-text-weight-bold has-text-danger">{{this.clientId}}</div>
      </div>
      <div class="api-subscription-detail">
        <div class="has-text-weight-bold has-text-grey-dark">Client Secret
          <a @click="copyToClipboard(clientSecret)"><font-awesome-icon icon="copy" class="has-text-danger"/></a>
        </div>
        <div class="has-text-weight-bold has-text-danger">{{this.clientSecret}}</div>
      </div>
      <div class="refresh-button-div">
        <button class="button is-danger has-text-weight-bold" @click="refreshCredentials">Refresh Credentials</button>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import Spinner from '../Utils/Spinner'
import DoodleMessage from '../Utils/DoodleMessage'

export default {
  name: 'apiCredentials',
  components: { DoodleMessage, Spinner },
  mixins: [formMixin],
  data: function () {
    return {
      clientId: null,
      clientSecret: null,
      showCopiedMessage: false,
      showCopiedMessageText: '',
      progressMessage: ''
    }
  },
  mounted () {
    this.progressMessage = 'Loading your credentials. Please wait...'
    this.executeAPIWitoutValiation({
      url: '/api/compiler-api/credentials',
      successAction: (data) => {
        this.clientId = data.clientId
        this.clientSecret = data.clientSecret
      },
      markCompleted: true,
      form: this.doodleForm,
      jdaCategory: window.jda.CATEGORY.API,
      jdaEvent: 'credentials',
      jdaLabel: ''
    })
  },
  methods: {
    refreshCredentials () {
      this.progressMessage = 'Refreshing your credentials. Please wait...'
      this.executeAPIWitoutValiation({
        url: '/api/compiler-api/refreshSecret',
        successAction: (data) => {
          this.clientSecret = data.clientSecret
        },
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.API,
        jdaEvent: 'refresh-secret',
        jdaLabel: ''
      })
    },
    copyToClipboard (text) {
      window.navigator.clipboard.writeText(text).then((e) => {
        this.showCopiedMessage = true
        this.showCopiedMessageText = 'Copied to clipboard'
        window._.delay(() => { this.showCopiedMessage = false }, 1000)
      }, (e) => {
        this.showCopiedMessage = true
        window._.delay(() => { this.showCopiedMessage = false }, 1000)
        this.showCopiedMessageText = 'Copy to clipboard failed'
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .api-subscription-title {
    text-decoration: underline;
    font-weight: bold;
    font-size: 1em;
  }

  .api-subscription-detail {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 0.75em;
  }

  .api-subscription-sub-detail {
    font-weight: bold;
    font-size: 0.8em;
  }

  .refresh-button-div {
    margin-top: 40px;
  }
</style>
